<!-- Contact Us Start -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">{{ 'contact.contact' | translate }}</h3>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <agm-map [latitude]="latitude" [longitude]="longitude">
            <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
          </agm-map>
          <!--       <div id="message"></div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Name*</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="Your name...">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email Address*</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="Your email...">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Message*</label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="Your message..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary">Send Message <i-feather class="icon-size-15 ml-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>-->
        </div>
      </div>
      <div class="col-lg-5">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="mail"></i-feather> : <span>bilalakkar777@gmail.com</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="link"></i-feather> : <span>www.bilalakkar.online</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="phone-call"></i-feather> : <span>(+212) 627 974 850 </span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="phone-call"></i-feather> : <span>Skype : BILALAKKAR777</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="map-pin"></i-feather> : <span>Tanger, {{ 'contact.mar' |
              translate }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
