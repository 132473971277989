<section class="timeline pb-120" id="features">
  <div class="text-center" class="section">
    <div class="menu-content pb-70">
      <div class="text-center mb-5">
        <h3 class="title mb-3">{{ 'Qualifications.Qualifications' | translate }}</h3>
      </div>
    </div>
  </div>
  <ul>
    <li>
      <div class="content">
        <h4>
          <time>SOFTWARE DEVELOPER</time>
        </h4>
        <p><b>{{ 'month.5' | translate }} 2022 {{ 'Qualifications.to' | translate }} {{ 'month.5' | translate }} 2024
          </b></p>
        <p><b>{{ 'Qualifications.inper' | translate }}</b></p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>SOFTWARE DEVELOPER</time>
        </h4>
        <p><b>{{ 'month.5' | translate }} 2021 {{ 'Qualifications.to' | translate }} {{ 'month.8' | translate }} 2021
          </b></p>
        <p><b>{{ 'Qualifications.SwD' | translate }}</b></p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>FREELANCE</time>
        </h4>
        <p><b>{{ 'month.3' | translate }} 2014 {{ 'Qualifications.to' | translate }} 2019</b></p>
        <p><b>Android / IOS Developer / WEB DESIGNER</b></p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>FULL-STACK DEVELOPER</time>
        </h4>
        <p><b>{{ 'month.4' | translate }} 2019 {{ 'Qualifications.to' | translate }} {{ 'month.7' | translate }} 2019
          </b></p>
        <p><b>{{ 'Qualifications.FSD' | translate }}</b></p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>IT & MARKETING</time>
        </h4>
        <p><b>{{ 'month.8' | translate }} 2016 {{ 'Qualifications.to' | translate }} {{ 'month.11' | translate }}
            2017</b></p>
        <p><b>{{ 'Qualifications.jobtext' | translate }}</b></p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>{{ 'Qualifications.Diploma' | translate }}</time>
        </h4>
        <p><b>{{ 'month.7' | translate }} 2014 {{ 'Qualifications.to' | translate }} {{ 'month.8' | translate }}
            2016</b></p>
        <p><b>{{ 'Qualifications.Diplomatxt' | translate }}</b> in Tangier</p>
      </div>
    </li>
    <li>
      <div class="content">
        <h4>
          <time>{{ 'Qualifications.Bachelor' | translate }}</time>
        </h4>
        <p><b>{{ 'month.6' | translate }} 2012 {{ 'Qualifications.to' | translate }} {{ 'month.10' | translate }}
            2014</b></p>
        <p><b>Abi Bakker Razzi </b> in Tangier</p>
      </div>
    </li>
  </ul>
</section>
