<!-- Why Choose Us Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <div class="mb-4 mb-lg-0">
          <div class="p-2 bg-soft-primary d-inline-block rounded mb-4">
            <div class="icon-xxl uim-icon-primary"><span class="uim-svg"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" width="1em">
                  <polygon class="uim-primary" points="12 12.3 3.5 7.05 12 1.8 20.5 7.05 12 12.3"></polygon>
                  <polygon class="uim-quaternary" points="12 22.2 12 12.3 20.5 7.05 20.5 16.95 12 22.2"></polygon>
                  <polygon class="uim-tertiary" points="12 22.2 3.5 16.95 3.5 7.05 12 12.3 12 22.2"></polygon>
                </svg></span>
            </div>
          </div>
          <h3 class="">{{ 'about.chooseme' | translate }}</h3>
          <p class="text-muted mb-4">{{ 'about.m2' | translate }}</p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-android"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Android</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">{{ 'accounts.android' | translate }}</p>
              <a href="https://play.google.com/store/apps/developer?id=Game+ComX&hl=en" class="btn btn-outline-link">{{
                'accounts.more' | translate }}</a>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-apple"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">IOS</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">{{ 'accounts.ios' | translate }}</p>
              <a href="https://apps.apple.com/us/developer/bilal-akkar/id1509112072" class="btn btn-outline-link">{{
                'accounts.more' | translate }}</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-github"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">GitHub</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">{{ 'accounts.Git' | translate }}</p>
              <a href="https://github.com/bilalakkar777" class="btn btn-outline-link">{{ 'accounts.more' | translate
                }}</a>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-linkedin"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">linkedIn</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">{{ 'accounts.link' | translate }}</p>
              <a href="https://www.linkedin.com/in/bilal-akkar-1aa390157/" class="btn btn-outline-link">{{
                'accounts.more' | translate }}</a>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-xing"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Xing</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">{{ 'accounts.xing' | translate }}</p>
              <a href="https://www.xing.com/profile/bilal_akkarwwwbilalakkaronline" class="btn btn-outline-link">{{
                'accounts.more' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us End -->
<section>
  <div class="container">
    <div class="menu-content pb-70">
      <div class="text-center mb-5">
        <h3 class="title mb-3">{{ 'about.skill' | translate }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="skill">
          <h4>Android apps (Ionic 4+)
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 75%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>IOS apps (Ionic 4+ / Swift 4)
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 85%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>PHP & Wordpress & .Net Core
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 65%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
      </div>
      <div class="col-md-4">
        <div class="skill">
          <h4>Angular 4+ & Bamboo | Atlassian
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 85%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>MySQL & MariaDB & Firebase
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 75%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>Unity 3D (C# & JavaScript)
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 70%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
      </div>
      <div class="col-md-4">
        <div class="skill">
          <h4>AI, ChatGPT & Git
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 50%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>Photoshop / Illustrator
          </h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 90%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
        <div class="skill">
          <h4>Mobil UI/UX</h4>
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 75%"></div><!-- /.progress-bar -->
          </div><!-- /.progress -->
        </div><!-- /.skill -->
      </div>
    </div>
  </div>
</section>
