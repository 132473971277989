import { Component, OnInit } from '@angular/core';

declare const $: any;
declare const Morris: any;
declare const slimscroll: any;

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent{

}
