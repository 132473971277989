<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="24" />
      <img src="assets/images/logo-light.png" alt="" class="logo-light" height="24" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
        <li class="nav-item">
          <a href="javascript: void(0);" class="nav-link">Email : bilalakkar777@gmail.com</a>
        </li>
        <li class="nav-item">
          <a href="javascript: void(0);" class="nav-link">Mobile : (+212)627-974-850</a>
        </li>
        <li class="nav-item">
          <a href="javascript: void(0);" class="nav-link">SKYPE LIVE: BILALAKKAR777</a>
        </li>
      </ul>
      <div class="navbar-container">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item" ngbDropdown>
              <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
                <a> {{currentLanguage}}</a>
                <p class="d-none">{{currentLanguage}}</p>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang text-left">
                <a class="dropdown-item py-1 lang" *ngFor="let item of languages" (click)="onLanguageChange(item)">
                  <img [src]="item.flag" class="langimg">
                  <span>{{item.label}}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--
          <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
            <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
              {{currentLanguage}} <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
            </ul>
          </div>

-->
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!-- Hero Start -->
  <section class="hero-3-bg" id="home">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-4 col-lg-5">
          <h1 class="hero-3-title text-shadow mb-0">BILAL AKKAR</h1>
          <p class="text-muted mt-4 mb-0">{{ 'about.me' | translate }}</p>
          <div class="mt-4 mb-5 pb-5">
            <div class="mt-4">
              <a href="./../../../assets/cvs/CV ENG.pdf" class="btn btn-primary mr-2">{{ 'cv.CV-ENG' | translate }}</a>
              <a href="./../../../assets/cvs/CV GER.pdf" class="btn btn-soft-primary">{{ 'cv.CV-GER' | translate }}</a>
            </div>
            <div class="mt-4 mb-5 pb-5">
              <ul class="list-inline footer-social-icon-content">
                <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon"
                    href="https://www.facebook.com/bilal.akkar.7">
                    <i-feather name="facebook"></i-feather>
                  </a></li>
                <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon"
                    href="https://www.instagram.com/bilal_ak77/">
                    <i-feather name="instagram"></i-feather>
                  </a></li>
                <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon"
                    href="https://www.linkedin.com/in/bilal-akkar-1aa390157/">
                    <i-feather name="linkedin"></i-feather>
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-7 offset-xl-1 col-lg-7 col-md-10">
          <img src="assets/images/5176625.png" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>
  </section>
  <ng-template #videocontent let-modal class="video-modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <youtube-player [videoId]="'JlvxDa7Sges'" height="450" width="780">
      </youtube-player>
    </div>
  </ng-template>
  <!-- Hero End -->

  <app-services></app-services>
  <app-features></app-features>
  <app-pricing></app-pricing>
  <app-blog></app-blog>
  <app-steuer></app-steuer>
  <app-contact></app-contact>
  <app-footer></app-footer>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="text-center">
      <h3 class="title mb-4">Welcome To Lezir</h3>
      <h4 class="text-uppercase text-primary"><b>Login</b></h4>
    </div>
    <div class="login-form mt-4">
      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">Username / Email</label>
          <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Youremail@gmail.com">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
        </div>
        <a href="javascript: void(0);" class="float-right text-muted font-size-15">Forgot Password.?</a>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck1">
          <label class="custom-control-label font-size-15" for="customCheck1">Remember Me</label>
        </div>
        <div class="text-center mt-4">
          <button type="submit" class="btn btn-primary">Login <i-feather class="icon-size-15 icon ml-1"
              name="arrow-right-circle"></i-feather></button>
        </div>
      </form>
      <div class="position-relative text-center mt-4">
        <span class="login-border"></span>
        <p class="social-login text-muted font-size-17">Social Login</p>
      </div>
      <div class="text-center">
        <ul class="list-inline mt-2 mb-3">
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-primary">
              <i-feather class="icon-xs" name="facebook"></i-feather>
            </a></li>
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-info">
              <i-feather class="icon-xs" name="twitter"></i-feather>
            </a></li>
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-danger">
              <i-feather class="icon-xs" name="instagram"></i-feather>
            </a></li>
          <li class="list-inline-item"><a href="javascript: void(0);" class="login-social-icon icon-success">
              <i-feather class="icon-xs" name="linkedin"></i-feather>
            </a></li>
        </ul>
        <p class="text-muted mb-0">New User? <a href="javascript: void(0);" class="text-primary">Signup</a></p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registercontent let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="text-center">
      <h3 class="title mb-4">Welcome To Lezir</h3>
      <h4 class="text-uppercase text-primary"><b>Register</b></h4>
    </div>
    <div class="login-form mt-4">
      <form>
        <div class="form-group">
          <label for="exampleInputUsername">User Name</label>
          <input type="text" class="form-control" id="exampleInputUsername" placeholder="Enter Name">
        </div>
        <div class="form-group">
          <label for="exampleInputEmail2">Email</label>
          <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Youremail@gmail.com">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword2">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Password">
        </div>
        <a href="javascript: void(0);" class="float-right text-muted font-size-15">Forgot Password.?</a>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck2">
          <label class="custom-control-label font-size-15" for="customCheck2">Remember Me</label>
        </div>
        <div class="text-center mt-4">
          <button type="submit" class="btn btn-primary">Register <i-feather class="icon-size-15 icon ml-1"
              name="arrow-right-circle"></i-feather></button>
        </div>
      </form>
      <div class="position-relative text-center mt-4">
        <span class="login-border"></span>
        <p class="social-login text-muted font-size-17">Social Login</p>
      </div>
      <div class="text-center">
        <ul class="list-inline mt-2 mb-3">
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-primary">
              <i-feather class="icon-xs" name="facebook"></i-feather>
            </a></li>
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-info">
              <i-feather class="icon-xs" name="twitter"></i-feather>
            </a></li>
          <li class="list-inline-item mr-3"><a href="javascript: void(0);" class="login-social-icon icon-danger">
              <i-feather class="icon-xs" name="instagram"></i-feather>
            </a></li>
          <li class="list-inline-item"><a href="javascript: void(0);" class="login-social-icon icon-success">
              <i-feather class="icon-xs" name="linkedin"></i-feather>
            </a></li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
