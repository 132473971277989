<!-- Contact Us Start -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">SteuerGo - Deine Steuer-App</h3>
          <p class="text-muted font-size-15">{{ 'steuer.suptitle' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <img src="/assets/img/Pro/steuer.png" width=100% height="100%">
          <!--       <div id="message"></div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Name*</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="Your name...">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email Address*</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="Your email...">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Message*</label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="Your message..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary">Send Message <i-feather class="icon-size-15 ml-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>-->
        </div>
      </div>
      <div class="col-lg-5">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <span>{{ 'steuer.text1' | translate }}</span>
          </p>
          <p class="">
            <span>{{ 'steuer.text2' | translate }}</span>
          </p>
          <p class="">
            <span>{{ 'steuer.text3' | translate }}</span>
          </p>
          <div class="mt-4">
            <a href="https://play.google.com/store/apps/details?id=de.steuergo.App&hl=en&gl=US"> <img
                src="/assets/img/Pro/android.png" class="btn"> </a>
            <a href="https://apps.apple.com/us/app/steuergo-deine-steuer-app/id1509424124"> <img
                src="/assets/img/Pro/ios.png" class="btn"> </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">INspares App</h3>
          <p class="text-muted font-size-15">{{ 'inperfcktion.suptitle' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <img src="/assets/img/Pro/inspares.png" width=100% height="100%">
          <!--       <div id="message"></div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Name*</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" placeholder="Your name...">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email Address*</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" placeholder="Your email...">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Message*</label>
                  <textarea id="comments" rows="4" class="form-control" placeholder="Your message..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" class="btn btn-primary">Send Message <i-feather class="icon-size-15 ml-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>-->
        </div>
      </div>
      <div class="col-lg-5">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <span>{{ 'inperfcktion.text1' | translate }}</span>
          </p>
          <p class="">
            <span>{{ 'steuer.text3' | translate }}</span>
          </p>
          <div class="mt-4">
            <a href="https://play.google.com/store/apps/details?id=de.inspares.app.spark&hl=en&gl=US"> <img
                src="/assets/img/Pro/android.png" class="btn"> </a>
            <a href="https://apps.apple.com/fi/app/inspares-app/id1595549270"> <img src="/assets/img/Pro/ios.png"
                class="btn"> </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
