<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">{{year}} Copyright © Bilal Akkar</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
