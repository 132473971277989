<!-- Pricing Start -->
<section class="section bg-light" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Ionic 4+ Full Starter App</h3>
          <p class="text-muted font-size-15">Copyright - Bilal Akkar.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center p-4">
          <div >
           <img class="image img-fluid" src="/assets/img/Pro/hotel-royal.png" width="200px" height="400px">
          </div>
          <h4 class="title mb-3">Hotel Royal</h4>
          <img  src="/assets/img/Pro/17.png" width="200px" height="400px">
          <p class="text-muted font-weight-semibold"></p>
          <a href="https://github.com/bilalakkar777/Ionic5-Angular-Hotel-Royal-Full-App" class="btn btn-outline-primary pr-btn">{{ 'accounts.more' | translate }}</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center active p-4">
          <div >
              <img class="image img-fluid" src="/assets/img/Pro/recipes.png" width="150px" height="300px">
            </div>
          <h4 class="title mb-3">Ion Recipes</h4>
          <img  src="/assets/img/Pro/rec.png" width="200px" height="400px">
          <p class="text-muted font-weight-semibold"></p>
          <a href="https://github.com/bilalakkar777/recipes" class="btn btn-primary pr-btn">{{ 'accounts.more' | translate }}</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="pricing-box rounded text-center p-4">
          <div >
           <img class="image img-fluid" src="/assets/img/Pro/shop.png" width="200px" height="400px">
          </div>
          <h4 class="title mb-3">Shoping App</h4>
          <img  src="/assets/img/Pro/4.png" width="200px" height="400px">
          <p class="text-muted font-weight-semibold"></p>
          <a href="javascript: void(0);" class="btn btn-outline-primary pr-btn">{{ 'pro.coming' | translate }}</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Pricing End -->
